<template>
    <div>
        <CompContent class="agendaroom-detail" title="意见详情">
            <template #operate>
                <div class="switch-box">
                    <div class="item" :class="{ ac: isPublic }" @click="onChangePublic(true)">公开展示意见</div>
                    <div class="item" :class="{ ac: !isPublic }" @click="onChangePublic(false)">不公开展示意见</div>
                </div>
                <!-- <Checkbox v-if="detail" :value="detail.status == 2" style="margin: 5px 0 5px 5px;color:red" @on-change="onChangeOpen">公开展示意见</Checkbox> -->
            </template>
            <div class="detail-page" v-if="detail">
                <div class="detail-base">
                    <div class="detail-box">
                        <div class="title-box">{{ detail.title }}</div>
                        <div class="info-box">
                            <div class="item">
                                提出时间: {{ detail.time }}
                                <div class="btn" @click="isDisplayUserInfo = !isDisplayUserInfo">
                                    <span>用户信息</span>
                                    <Icon class="icon" type="ios-arrow-down" size="12px" :style="{ transform: 'rotate(' + (isDisplayUserInfo ? '180deg' : '0') + ')' }" />
                                </div>
                            </div>
                            <div class="item" v-if="detail.processTime">发现时间: {{ $core.formatDate(new Date(detail.processTime), "yyyy年MM月dd日") }}</div>
                            <div class="item" v-if="detail.processAddr">位置: {{ detail.processAddr }}</div>
                            <div class="item">提出人: {{ detail.createBy }}</div>
                            <block v-if="userinfo" v-show="isDisplayUserInfo">
                                <div class="item">手机号: {{ userinfo.mobile || "-" }}</div>
                                <div class="item">现住地址: {{ userinfo.addr || "-" }}</div>
                                <div class="item">是否家庭户代表: {{ userinfo.contact == "1" ? "是" : "否" }}</div>
                            </block>
                        </div>
                        <div class="text-box" v-html="detail.content"></div>
                        <div class="photo-box" v-if="detail.photos && detail.photos.length > 0">
                            <div class="item-box" v-for="(item, idx) in detail.photos" :key="'photo-' + idx" @click="onPreviewImage(item, detail.photos)">
                                <image class="img" :src="item" mode="aspectFill" />
                            </div>
                        </div>
                    </div>

                    <!-- 处理结果 -->
                    <div class="result-box" v-if="detail.flowStatus !== '0'">
                        <div class="title">处理结果</div>
                        <div class="item-box">
                            <div class="name">处理时间:</div>
                            <div class="value">{{ $core.formatDate(new Date(detail.adviceResultVo.gmtCreate), "yyyy-MM-dd hh:mm:ss") }}</div>
                        </div>
                        <div class="item-box">
                            <div class="name">处理方法:</div>
                            <div class="value">{{ { 1: "回复并关闭", 2: "关联已有议事", 3: "发起新议事" }[detail.adviceResultVo.createWay] }}</div>
                        </div>
                        <div class="item-box" v-if="detail.adviceResultVo.createWay === '1'">
                            <div class="name">回复内容:</div>
                            <div class="value">{{ detail.adviceResultVo.content }}</div>
                        </div>
                        <div class="item-box" v-else-if="detail.adviceResultVo.createWay === '2'">
                            <div class="name">所关联的议事:</div>
                            <div class="value highlight" @click="onJumpDetail(detail.issueId)">{{ detail.adviceResultVo.content }}</div>
                        </div>
                    </div>

                    <div class="handle-box" v-else>
                        <div class="head-box">
                            <div class="title-box">意见处理</div>
                        </div>
                        <div class="radio-box">
                            <RadioGroup class="radio-group" v-model="handleType">
                                <Radio v-for="(item, idx) in ['回复并关闭', '关联已有议事', '发起新议事']" :key="item" :label="idx">{{ item }}</Radio>
                            </RadioGroup>
                        </div>

                        <div class="textarea-box" ref="textarea_box" v-show="handleType === 0" contenteditable="true" v-html="reply"></div>

                        <div class="select-box" v-show="handleType === 1">
                            <div class="search-box">
                                <input v-model="proceedings_search" placeholder="输入议事名称进行搜索" />
                                <div class="submit" @click="onSubmitProceedingsSearch">搜索</div>
                            </div>
                            <block v-if="proceedings">
                                <div class="title">搜索结果：</div>
                                <div class="list-box">
                                    <div class="item" v-for="(item, idx) in proceedings" :key="'list' + idx" @click="proceedings_current = item">
                                        <p>{{ item.title }}</p>
                                        <Icon type="ios-checkmark-circle" size="20" color="#1990ff" v-if="item.id === proceedings_current.id" />
                                    </div>
                                    <div class="empty" v-if="proceedings.length === 0">暂无对应议事</div>
                                </div>
                            </block>
                        </div>

                        <button class="submit-btn" @click="onSubmitHandle">提交</button>
                    </div>
                </div>

                <!-- 留言模块 -->
                <div class="comment-box">
                    <div class="comment-head">
                        <div class="title">大家的看法</div>
                        <div class="whole" @click="onJumpSeconded">查看全部 ></div>
                        <div class="display" @click="onSetAllCommentShow">
                            <!-- <checkbox></checkbox> -->
                            <div class="name">全部显示</div>
                        </div>
                    </div>

                    <CompComment class="comment-base" :forumId="detail.id" :manage="true" forumType="CHAMBER"></CompComment>
                </div>
            </div>
        </CompContent>

        <Preview ref="preview"></Preview>
        <Publish ref="publish" @on-submit="Route.back()"></Publish>
    </div>
</template>

<script>
import CompContent from "../customform/components/CompContent"
import CompComment from "./components/CompComment.vue"
import ElemSwitch from "../customform/components/ElemSwitch"
import Preview from "../comment/components/Preview.vue"

import Request from "../jointly/utils/request"
import Route from "../communitymobilization/entity/Route"
import Publish from "./components/Publish"

export default {
    components: { CompContent, CompComment, Preview, Publish, ElemSwitch },

    data() {
        return {
            Route: Route,
            detail: null,
            // 处理类型
            handleType: 0,
            // 回复文本内容
            reply: "",
            // 议事列表
            proceedings: null,
            // 当前选择的议事
            proceedings_current: {},
            // 议事搜索
            proceedings_search: "",
            // 发起人消息
            userinfo: null,
            // 显示用户信息
            isDisplayUserInfo: false,
            // 是否公开
            isPublic: false,
        }
    },

    watch: {
        handleType(v) {
            if (v === 1 && !this.proceedings) {
                this.getProceedings()
            }
        },

        isDisplayUserInfo(v) {
            if (v && !this.userinfo) {
                this.getUserInfo()
            }
        },
    },

    created() {
        this.id = this.$core.getUrlParam("id")
        this.getDetailData()
    },

    methods: {
        /**
         * 获取议事列表
         */
        onSubmitProceedingsSearch() {
            if (!this.proceedings_search) {
                return (this.proceedings = null)
            }

            Request.get("/gateway/syinfopublish/api/app/chamber/listIssueOption?title=" + this.proceedings_search).then(res => {
                this.proceedings = res
            })
        },

        /**
         * 获取详情数据
         */
        getDetailData() {
            Request.get("/gateway/syinfopublish/api/app/chamber/getChamberAdviceInfo", {
                id: this.id,
            }).then(res => {
                if (res.adviceResultVo && res.adviceResultVo.content && /^\{.*\}$/.test(res.adviceResultVo.content)) {
                    res.adviceResultVo.content = JSON.parse(res.adviceResultVo.content)
                }

                // 解析图片 JSON 字符串
                if (res.photo && /^\[.*\]$/.test(res.photo)) {
                    res.photos = JSON.parse(res.photo)
                }

                // 提出时间
                res.time = this.$core.formatDate(new Date(res.gmtCreate), "yyyy年MM月dd日")

                this.detail = res
                this.isPublic = res.status == 2
            })
        },

        getUserInfo() {
            Request.get("/syaa/app/sy/user/home/V2/getOneMemberOnlineById", {
                custGlobalId: this.detail.custGlobalId,
            }).then(res => {
                this.userinfo = res || {}
            })
        },

        /**
         * 提交处理事件
         */
        onSubmitHandle() {
            if (this.handleType === 2) {
                var d = this.detail

                var value = {
                    title: d.title,
                    content: d.content,
                    issueId: d.id,
                    photos: d.photos,
                }

                return this.$refs.publish.display(null, value)
            }

            var content
            var issueId

            if (this.handleType === 0) {
                content = this.$refs.textarea_box.innerText

                if (!content) {
                    return this.$Message.warning("请输入回复内容")
                }
            }

            if (this.handleType === 1) {
                const psc = this.proceedings_current

                if (!psc?.id) {
                    return this.$Message.warning("请选择已有议事")
                }

                issueId = psc.id
                content = psc.title
            }

            Request.post(
                "/gateway/syinfopublish/api/app/chamber/commentsHandling",
                {
                    issueId: issueId,
                    adviceId: this.id,
                    createWay: [1, 2, 3][this.handleType],
                    content: content,
                },
                {
                    json: true,
                }
            ).then(() => {
                this.$Message.success("处理完成")
                // 返回上一页
                Route.back()
            })
        },

        /**
         * 设置所有显示评论
         */
        onSetAllCommentShow() {
            Request.post(
                "/gateway/syinfopublish/api/app/infoComments/updateAllStatus",
                {
                    infoId: this.id,
                    status: 2,
                    orgCode: this.$core.getOrgCode(),
                    infoType: "CHAMBER",
                },
                {
                    success: "设置成功",
                }
            )
        },

        /**
         * 预览图片
         */
        onPreviewImage(url, urls) {
            this.$refs.preview.display(url, urls)
        },

        /**
         * 跳转详情
         */
        onJumpDetail(id) {
            Route.jump("/agendaroomevolve", {
                id: id,
            })
        },

        onJumpSeconded() {
            Route.jump("/agendaroomcomment", {
                i: this.id,
            })
        },

        onChangePublic(value) {
            if (value === this.isPublic) return

            if (!this.detail) {
                return this.$Message.warning("数据加载中，请稍后！")
            }

            this.isPublic = value

            Request.post(
                "/gateway/syinfopublish/api/app/chamber/updateAdviceStatus",
                {
                    id: this.id,
                },
                {
                    json: true,
                }
            ).then(() => {
                this.$Message.success("修改成功")
                // 取反值
                this.$set(this.detail, "status", this.detail.status == 2 ? 1 : 2)
            })
        },
    },
}
</script>

<style lang="less">
.agendaroom-detail {
    .switch-box {
        display: flex;
        border: 1px solid #e3e3e3;
        border-radius: 8px;
        padding: 3px;

        > .item {
            cursor: pointer;
            padding: 5px 15px;
            color: #666;
            border-radius: 6px;
            font-size: 14px;
            transition: all 0.3s;
            font-weight: bold;

            &.ac {
                color: #fff;

                &:nth-child(1) {
                    background: #2faaf7;
                }

                &:nth-child(2) {
                    background: red;
                }
            }
        }
    }

    .detail-page {
        width: 100%;
        height: 100%;
        display: flex;

        > .comment-box {
            position: relative;
            flex-grow: 1;
            width: calc(100% - 520px);
            overflow: hidden;
            display: flex;
            flex-direction: column;

            .comment-head {
                margin: 20px 15px 5px 15px;
                display: flex;
                align-items: flex-end;
                flex-shrink: 0;

                .title {
                    font-size: 17px;
                    line-height: 1;
                }

                .whole {
                    cursor: pointer;
                    margin-left: 10px;
                    font-size: 13px;
                    line-height: 1;
                    color: #1990ff;
                }

                .display {
                    cursor: pointer;
                    flex-shrink: 0;
                    display: flex;
                    align-items: center;
                    margin-left: auto;

                    .name {
                        margin-left: 5px;
                        font-size: 12px;
                        line-height: 1;
                        color: #666;
                    }

                    input {
                        top: 0;
                        width: 15px;
                        height: 15px;
                        font-size: 15px;
                    }

                    .weui-cells_checkbox {
                        display: flex;
                    }
                }
            }

            > .comment-base {
                flex-grow: 1;
            }
        }
    }

    .detail-base {
        width: 500px;
        height: 100%;
        padding: 0 20px;
        flex-shrink: 0;
        border-right: 1px solid #e3e3e3;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow-y: auto;

        .detail-box {
            width: 100%;

            > .title-box {
                padding: 20px 0;
                text-align: center;
                font-size: 22px;
                color: #000;
            }

            .info-box {
                position: relative;

                .item {
                    height: 27px;
                    font-size: 13px;
                    color: #666;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .btn {
                        cursor: pointer;
                        padding: 0 12px;
                        height: 27px;
                        line-height: 1;
                        color: #6a6a6a;
                        background: #f1f1f1;
                        display: flex;
                        align-items: center;
                        border-radius: 4px;

                        .icon {
                            margin-left: 5px;
                            transition: all 0.2s;
                        }
                    }
                }
            }

            .text-box {
                padding: 10px 0;
                color: #333;
                letter-spacing: 1px;
            }

            .photo-box {
                padding: 20px 0 0 0;
                margin: 0 -10px;
                display: flex;
                flex-wrap: wrap;

                .item-box {
                    position: relative;
                    width: calc(100% / 3 - 20px);
                    padding-bottom: 28%;
                    height: 0;
                    margin: 10px;

                    .img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        border-radius: 10px;
                        overflow: hidden;
                    }
                }
            }
        }

        .handle-box {
            padding: 20px;
            width: 100%;
            border-top: 1px solid #f3f3f3;

            .head-box {
                width: 100%;
                display: flex;
                justify-content: center;

                > .title-box {
                    font-weight: bold;
                    display: flex;
                    align-items: center;
                    padding: 5px 10px;
                    border-bottom: 1px solid #e3e3e3;
                }
            }

            .radio-box {
                margin: 20px 0;
                font-size: 13px;

                .radio-group {
                    display: flex;
                    justify-content: space-between;
                }
            }

            .textarea-box {
                padding: 10px;
                border: 1px solid #e3e3e3;
                border-radius: 8px;
                font-size: 15px;
                min-height: 150px;
                max-height: 300px;
                overflow-x: hidden;
                overflow-y: auto;
                outline: none;
                transition: all 0.2s;

                &:hover,
                &:focus {
                    border-color: #b3b3b3;
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                }
            }

            .select-box {
                .search-box {
                    margin: 10px 0;
                    padding: 5px 5px 5px 10px;
                    border: 1px solid #e3e3e3;
                    border-radius: 8px;
                    display: flex;
                    align-items: center;

                    input {
                        border: initial;
                        flex-grow: 1;
                    }

                    .submit {
                        cursor: pointer;
                        margin-left: 20px;
                        padding: 10px 20px;
                        line-height: 1;
                        background: #1990ff;
                        color: #fff;
                        border-radius: 6px;
                        flex-shrink: 0;
                    }
                }

                .list-box {
                    border: 1px solid #e3e3e3;
                    border-radius: 8px;
                    max-height: 150px;
                    overflow-y: auto;

                    .item {
                        padding: 10px;
                        border-bottom: 0;
                        border-bottom: 1px solid #f3f3f3;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }

                    .empty {
                        padding: 10px;
                        text-align: center;
                        font-size: 14px;
                        color: #888;
                    }
                }
            }

            .title {
                margin-top: 5px;
                font-size: 13px;
                color: #888;
                line-height: 35px;
            }

            .submit-btn {
                cursor: pointer;
                width: 100%;
                height: 40px;
                background: #14b16e;
                color: #fff;
                border-radius: 7px;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
                margin-top: 15px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                border: initial;

                &::after {
                    border: initial;
                }
            }
        }

        .result-box {
            border-top: 1px solid #f3f3f3;
            padding: 25px 0;
            margin-top: 20px;

            .title {
                font-size: 18px;
                font-weight: bold;
                line-height: 1;
                padding-bottom: 10px;
            }

            .item-box {
                margin-top: 5px;
                display: flex;
                align-items: flex-start;
                line-height: 25px;

                .name {
                    color: #666;
                    margin-right: 10px;
                    flex-shrink: 0;
                }

                .value {
                    color: #333;

                    &.highlight {
                        cursor: pointer;
                        color: #1990ff;
                    }
                }
            }
        }
    }
}
</style>
